.hero-section {
    position: relative;
    width: 100vw;
    min-height: 100vh;
    /* Assure que la section occupe tout l'écran */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: env(safe-area-inset-bottom);
}

.hero-image-container {
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    text-shadow: none;
}

.hero-image-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    /* Couche sombre plus prononcée */
    z-index: 1;
}

.hero-placeholder {
    font-size: 20px;
    color: #888;
}

.hero-image-container h1,
.hero-image-container p,
.hero-image-container a {
    color: white;
    text-align: center;
    z-index: 2;
}

.hero-image-container h1 {
    font-size: 4rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    margin-bottom: 20px;
}

.hero-image-container p {
    font-size: 1.5rem;
    margin-bottom: 40px;
}

.hero-image-container a {
    padding: 10px 20px;
    background-color: #336699;
    /* background-color: #25620B; */
    text-decoration: none;
    border-radius: 10px;
    font-size: 1.2rem;
}

/* .hero-image-containe a:hover {
    background-color: #336699;
    /* background-color: #1d4d09;
}  */

@media (max-width: 576px) {
    .hero-section {
        background-position: top center;
        /* Positionne l'image pour afficher une partie clé */
    }
}

@media (max-width: 768px) {
    .hero-section h1 {
        font-size: 2rem;
        /* Diminue la taille du texte */
        line-height: 1.2;
        /* Ajuste l'espacement entre les lignes */
    }

    .hero-section p {
        font-size: 1rem;
        /* Diminue également la taille du sous-titre */
    }
}