.cookie-banner {
    display: flex;
    position: fixed;
    bottom: 20px;
    left: 20px;
    max-width: 300px;
    background-color: #fff;
    color: black;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 20px;
    z-index: 1000;
    animation: slideIn 0.5s ease-out;
    justify-items: center;
}

.cookie-content h2 {
    font-size: 1.2rem;
    margin-top: 5px;
}

.cookie-content p {
    font-size: 0.9rem;
    margin-bottom: 10px;
}

.cookie-content a {
    font-size: 0.8rem;
    color: #333;
    text-decoration: underline;
}

.cookie-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.btn {
    border: none;
    border-radius: 5px;
    padding: 8px;
    cursor: pointer;
    font-weight: bold;
    font-size: 0.8rem;
}

.btn-refuse {
    background-color: #f44336;
    color: white;
}

.btn-accept {
    background-color: #4CAF50;
    color: white;
}

@keyframes slideIn {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0);
    }
}