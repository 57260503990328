.footer-section {
    position: sticky;
    display: flex;
    bottom: 0;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    background-color: rgba(207, 207, 207, 0.5);
}

.footer-content {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.social-links {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 10px;
}

.social-links a {
    color: white;
    font-size: 1.5rem;
    transition: color 0.3s;
}

.social-links a:hover {
    color: #336699;
}

.footer-cookie {
    width: 100px;
    height: auto;
}

.footer-link {
    color: #ffffff;
    text-decoration: none;
    font-size: 0.9rem;
}

.footer-link:hover {
    text-decoration: underline;
}

.footer-text {
    display: flex;
    flex-direction: column;
    text-align: center;
    /* Nouveau style */
}


/* ---------- Medias Queries ---------- */
/* Footer */
/* Media Query pour ajuster le style sur mobile */
@media (max-width: 576px) {
    .footer-content {
        flex-direction: row;
        align-items: center;
        text-align: center;
    }
}