html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  /* background-color: #C45648; */
}

.webradio-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: transparent; */
  padding: 20px;
}

.carousel-container {
  display: flex;
  justify-content: center;
  /* padding: 10px 0 20px; */
  width: 100%;
  margin: 0 auto;
  overflow: visible;
  margin-top: 30px;
  margin-bottom: 20px;
}

.radio-carousel {
  display: flex;
  flex-wrap: nowrap;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  touch-action: pan-x;
  scroll-behavior: smooth;
  align-items: center;
  overflow: visible;
}

.radio-carousel::-webkit-scrollbar {
  display: none;
  /* Masquer la scrollbar */
}

.radio-item {
  /* flex: 0 0 auto; */
  scroll-snap-align: center;
  width: 150px;
  height: 150px;
  border-radius: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
}

.radio-item img {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-fit: cover;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
}

.radio-item:hover img {
  transform: scale(1.1);
  box-shadow: 0px 4px 12px rgba(255, 165, 0, 0.6);
}

.radio-item.active img {
  animation: pulse 1.5s infinite;
}

/* Animation Pulse logos */
@keyframes pulse {

  0%,
  100% {
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.6);
  }

  50% {
    box-shadow: 0 0 27px rgba(255, 255, 255, 1);
  }
}

/* Animation Cercles */
@keyframes moveCircle {
  0% {
    transform: translate(-10px, -10px) scale(1);
    opacity: 0.7;
  }

  50% {
    transform: translate(30px, 30px) scale(1.2);
    opacity: 0.9;
  }

  100% {
    transform: translate(-10px, -10px) scale(1);
    opacity: 0.7;
  }
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: linear-gradient(135deg, #4e54c8, #8f94fb);
  /* Couleurs dégradées */
}

.circle {
  position: absolute;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  animation: moveCircle 8s infinite ease-in-out;
  filter: blur(10px);
  /* Ajout de flou */
}

.circle:nth-child(1) {
  width: 250px;
  height: 250px;
  top: 20%;
  left: 30%;
}

.circle:nth-child(2) {
  width: 200px;
  height: 200px;
  top: 50%;
  left: 70%;
  animation-delay: 2s;
}

.circle:nth-child(3) {
  width: 350px;
  height: 350px;
  top: 60%;
  left: 20%;
  animation-delay: 4s;
}

/* Cartes autres stations */
#other-stations-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 50px;
}

.station-card {
  position: relative;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  width: 200px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.station-card:hover {
  transform: scale(1.05);
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.3);
}

.station-card-logo {
  width: 100px;
  height: 100px;
  margin: 10px auto;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}

.card-time {
  color: #f1c40f;
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 5px;
}

.card-artist,
.card-title {
  color: white;
  font-size: 0.9rem;
  margin-bottom: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.play-button-container {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}

.circle-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  transition: all 0.3s ease;
  color: white;
  background-color: #2ecc71;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.circle-button.play:hover {
  background-color: #27ae60;
}

/* Container texte */
.title-container {
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 10px 20px;
  border-radius: 15px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 30px;
  text-align: center;
}

.title-container h2 {
  margin: 0;
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: bold;
}

.title-container-cards {
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 10px 20px;
  border-radius: 15px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 150px;
  text-align: center;
}

.title-container-cards h2 {
  margin: 0;
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: bold;
}

/* Reseaux sociaux */
.hero-social-links .social-links {
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  gap: 15px;
  background: rgba(255, 255, 255, 0.1);
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.hero-social-links .social-links a {
  color: white;
  font-size: 2rem;
  /* Augmente la taille des icônes */
  transition: color 0.3s;
}

.hero-social-links .social-links a:hover {
  color: #336699;
}


/* ---------- Medias Queries ---------- */

/* Smartphones et tablettes en portrait */
@media (max-width: 1024px) {
  .radio-carousel {
    flex-wrap: nowrap;
    /* Désactive le wrapping */
    justify-content: flex-start;
    /* Aligne les logos à gauche */
    overflow-x: auto;
    /* Active le défilement horizontal */
  }

  .radio-item {
    margin: 0 10px;
  }

  .carousel-container {
    height: 210px;
    padding: 10px 0;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .title-container-cards {
    margin-top: 50px;
  }
}

/* iPads en mode paysage (jusqu'à 1366px) */
@media (min-width: 1025px) and (max-width: 1366px) and (orientation: landscape) {
  .radio-carousel {
    flex-wrap: nowrap;
    /* Désactive le wrapping */
    justify-content: flex-start;
    /* Alignement à gauche */
    overflow-x: auto;
    /* Active le défilement horizontal */
    gap: 10px;
    /* Réduit légèrement l'espacement */
  }

  .radio-item {
    margin: 0 10px;
  }

  .carousel-container {
    height: 210px;
    padding: 10px 0;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

/* Grands écrans Desktop (> 1366px) */
@media (min-width: 1367px) {
  .radio-carousel {
    flex-wrap: wrap;
    /* Autorise le wrapping */
    gap: 20px;
    /* Espace entre les logos */
    justify-content: center;
    /* Centre les éléments */
  }

  .carousel-container {
    width: 80%;
  }

  .radio-item {
    margin-bottom: 20px;
  }
}