#webradio-player-history {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(207, 207, 207, 0.5);
    padding: 20px;
    border-radius: 10px;
    width: 1000px;
    max-width: 1000px;
    margin: 0 auto;
    height: 350px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.6);
}

#album-cover {
    width: 330px;
    height: 330px;
    border-radius: 10px;
    margin-right: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

.player-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 1;
    text-align: center;
    height: 100%;
}

.station-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30%;
    min-width: 30%;
    max-width: 100%;
    height: 350px;
    gap: 30px;
    overflow: hidden;
    flex: 0 0 30%;
}

.station-name {
    font-size: 1.5rem;
    color: #336699;
    font-weight: bold;
    text-align: center;
}

/* Conteneur principal pour Artiste/Titre */
#current-song {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    gap: 5px;
}

.scrolling-text {
    display: inline-block;
    animation: scroll-text 10s linear infinite;
    white-space: nowrap;
    will-change: transform;
}

@keyframes scroll-text {
    0% {
        transform: translateX(5%);
    }

    100% {
        transform: translateX(-100%);
    }
}

#artist-name {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
}

#song-title {
    font-size: 1.2rem;
    color: #e6f0f7;
    text-align: center;
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
}

.current-time {
    font-size: 1.1em;
    color: #ffffff;
    text-align: center;
    opacity: 0.8;
}

#play-pause {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.circle-button {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

.circle-button.stop {
    background-color: #e74c3c;
    width: 100%;
    height: 100%;
}

.circle-button.stop i {
    font-size: 2.5rem;
    color: white;
}

#volume-control {
    display: flex;
    align-items: center;
    justify-content: center;
}

#volume-slider {
    width: 100%;
    margin: 0 10px;
    appearance: none;
    height: 5px;
    background: #f39c12;
    outline: none;
    border-radius: 5px;
}

#volume-slider::-webkit-slider-thumb,
#volume-slider::-moz-range-thumb {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #f39c12;
}

.sound-controls {
    display: flex;
    align-items: center;
    gap: 35px;
}

.sound-bars {
    display: flex;
    justify-content: center;
}

.bar {
    width: 5px;
    background-color: #336699;
    margin: 0 1px;
    animation: bounce 0.5s infinite alternate;
}

.bar:nth-child(1) {
    height: 10px;
    animation-duration: 0.4s;
}

.bar:nth-child(2) {
    height: 15px;
    animation-duration: 0.5s;
}

.bar:nth-child(3) {
    height: 8px;
    animation-duration: 0.6s;
}

@keyframes bounce {
    from {
        transform: scaleY(1);
    }

    to {
        transform: scaleY(2);
    }
}

.icon-live {
    color: red;
    font-size: 1.2rem;
}

/* -------- Section historique -------- */
#history {
    height: calc(100% - 40px);
    overflow-y: auto;
    padding-top: 10px;
}

#history-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 350px;
    overflow-y: auto;
    margin-left: 20px;
    overflow-y: scroll;
}

#history-title {
    margin: 0;
    text-align: center;
    color: #f1c40f;
    padding: 5px 0;
    width: 100%;
    height: 40px;
    position: sticky;
    top: 0;
    z-index: 1;
}

#history-title hr {
    border: none;
    height: 2px;
    background-color: #336699;
    margin-top: 15px;
}

#history-title h4 {
    margin: 0;
    text-align: center;
    font-size: 1.1rem;
    color: #336699;
}

#history-cards {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
}

.history-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    line-height: 1.5;
    max-width: 100%;
    overflow: hidden;

}

.history-card:hover {
    transform: scale(1.02);
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.3);
}

.card-time {
    color: #f1c40f;
    font-size: 1rem;
    font-weight: bold;
}

.card-artist {
    color: #ecf0f1;
    font-size: 1rem;
    margin-top: 5px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
}

.card-title {
    color: #ffffff;
    font-size: 1rem;
    margin-top: 2px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
}

/* ---------- Medias Queries ---------- */

/* Player */
/* Smartphones Portrait */
@media only screen and (max-width: 576px) {
    #webradio-player-history {
        display: flex;
        flex-direction: column;
        width: 80%;
        max-width: 300px;
        margin: 0 auto;
        height: 1000px;
    }

    #album-cover {
        width: 100%;
        height: 100%;
        margin: auto;
    }

    .station-info {
        width: 100%;
        height: 300px;
    }

    .player-content {
        display: flex;
        flex-direction: column;
    }

    #volume-control {
        display: none;

    }

    #history-container {
        width: 100%;
        margin: auto;
    }
}

/* Tablettes Portrait */
@media only screen and (min-width: 700px) and (max-width: 1179px) {

    /* Conteneur principal structuré en deux colonnes */
    #webradio-player-history {
        display: grid;
        grid-template-columns: 350px 1fr;
        width: 700px;
        max-width: 700px;
        height: 700px;

    }

    /* Colonne de gauche : album-cover-container et station-info empilés verticalement */
    #album-cover-container {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        width: 330px;
    }

    .station-info {
        width: 350px;
    }

    /* Colonne de droite : history-container prenant tout l'espace restant */
    #history-container {
        grid-column: 2;
        grid-row: 1 / span 2;
        width: 340px;
        height: 700px;
        margin: auto;
    }

    #history ul {
        max-height: 700px;
    }
}